export const state = {
    slider: {},
};

export const initSlider = () => {
    state.slider.length = document.querySelectorAll('.slider__item').length;
    state.slider.active = 0;
    state.slider.timeElapsed = 0;
};

export const updateSliderActive = (index) =>
    (state.slider.active =
        ((index % state.slider.length) + state.slider.length) %
        state.slider.length);

export const updateSliderElapsedTime = (reset) => {
    if (reset) return (state.slider.timeElapsed = 0);
    state.slider.timeElapsed++;
};
