class SliderView {
    _slider;

    _translateSlides(active) {
        document
            .querySelectorAll('.slider__item')
            .forEach(
                (item, i) =>
                    (item.style.transform = `translate3d(${
                        (i - active) * 100
                    }%, 0, 0)`)
            );
    }

    _activateButtons(active) {
        const buttons = document.querySelectorAll('.slider__button');
        buttons.forEach((button) =>
            button.classList.remove('slider__button--active')
        );
        buttons[active].classList.add('slider__button--active');
    }

    render(slider) {
        this._slider = slider;

        // Create all slider buttons
        const markup = new Array(slider.length)
            .fill(0)
            .map(
                (_, i) =>
                    `<button class="slider__button" data-index=${i}></button>`
            )
            .join('');
        document.querySelector('.slider__buttons').innerHTML = markup;

        // Mark the active button
        document
            .querySelectorAll('.slider__button')
            [slider.active].classList.add('slider__button--active');

        // Translate each slide
        this._translateSlides(slider.active);
    }

    update() {
        this._translateSlides(this._slider.active);
        this._activateButtons(this._slider.active);
    }

    addHandlerRender(handler) {
        window.addEventListener('load', handler);
    }

    addHandlerButtons(handler) {
        document
            .querySelector('.slider__buttons')
            .addEventListener('click', handler);
    }

    addHandlerSwap(handler) {
        document
            .querySelector('.slider__swap')
            .addEventListener('click', handler);
    }

    addHandlerAutoscroll(handler) {
        window.addEventListener('load', handler);
    }
}

export default new SliderView();
